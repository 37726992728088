import React from "react";
import {Box, Button, Typography} from '@mui/material';
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useAuthContext} from "../../auth/hooks";
import {paths} from '../../routes/paths';
import {getUserType, UserTypes} from "../company/utils";
import LoginRegisterDialog from "../../layouts/credits/login-register-dialog";
import {BecomePremiumToAccessContent} from "./become-premium-to-access-content";

export function BecomePremiumToAccessDialog({ticker, open, onClose, premiumOnly}: {
  ticker: string | undefined,
  open: boolean,
  onClose: () => void
  premiumOnly?: boolean
}) {
  const {authenticated, user} = useAuthContext();

  const renderAction = () => {
    const userType = getUserType(user, ticker || "");
    if (userType === UserTypes.FreeNoCredits) {
      return (<Button
        variant="contained"
        href={paths.main.plans}
      >
        Start Free Trial 🚀
      </Button>);
    }
    return null
  }

  const renderTitle = () => "Start Free Trial"

  if (!authenticated) {
    return <LoginRegisterDialog dialogOpen={open} onClose={onClose}/>
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{renderTitle()}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{position: 'absolute', right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>


      </DialogTitle>
      <DialogContent>
        <Box sx={{p: 2}}>
        <BecomePremiumToAccessContent ticker={ticker} premiumOnly={premiumOnly}/>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
