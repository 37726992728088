// React
import React, {useEffect, useState} from "react";
// @mui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Box, IconButton, Snackbar, Stack, useMediaQuery} from "@mui/material";
import Alert from "@mui/material/Alert";
import {useLocation, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

// components
import {useAuthContext} from "../../auth/hooks";
import Iconify from "../../components/iconify";
import CustomSearchbar from "../_common/custom-searchbar";
import {RouterLink} from "../../routes/components";
import {paths} from "../../routes/paths";
import {enlargeOnHover} from "../../common/styles";
import {useBoolean} from "../../hooks/use-boolean";
import Label from "../../components/label";
import axios, {endpoints} from "../../utils/axios";
import GeneratedOn from "../../components/utility_components/generated-on";


export default function DiscoverLayout() {
  const {user} = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation()

  const [afterRegister, setAfterRegister] = useState(user?.is_new && !localStorage.getItem('afterRegisterMessageShown'));
  const [currentLocationHash, setCurrentLocationHash] = useState(location?.hash !== "" ? location.hash : '#watchlist');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (location.hash !== "") {
      setCurrentLocationHash(location.hash);
    } else {
      setCurrentLocationHash('#watchlist');
    }
  }, [location]);

  const handleAfterRegisterClose = () => {
    setAfterRegister(false);
    localStorage.setItem('afterRegisterMessageShown', 'true');
  }

  const renderSnackbar = (
    <Snackbar open={afterRegister} autoHideDuration={10000} onClose={handleAfterRegisterClose}>
      <Alert onClose={handleAfterRegisterClose} severity="success" sx={{width: '100%'}}>
        You have registered successfully! Welcome to ValueHunter!
      </Alert>
    </Snackbar>
  )

  const [topCompaniesLastUpdated, setTopCompaniesLastUpdated] = useState<string>("");
  const [topInsiderBoughtLastUpdated, setTopInsiderBoughtLastUpdated] = useState<string>("");
  const [magicCompaniesLastUpdated, setMagicCompaniesLastUpdated] = useState<string>("");
  const [targetPricesLastUpdated, setTargetPricesLastUpdated] = useState<string>("");

  useEffect(() => {
    const getTopCompaniesLastUpdated = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.dashboard.top_companies}`);
        setTopCompaniesLastUpdated(result.data?.date);
      } catch (error) {
        console.error("Error fetching top companies:", error);
      }
    };
    const getTopInsiderBuysCompanies = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.curatedStocks}?type=top_insider_buys`);
        setTopInsiderBoughtLastUpdated(result.data.updated_date);
      } catch (error) {
        console.error("Error fetching top companies:", error);
      }
    };
    const getMagicFormulaCompanies = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.curatedStocks}?type=magic_formula`);
        setMagicCompaniesLastUpdated(result.data.updated_date);
      } catch (error) {
        console.error("Error fetching top companies:", error);

      }
    };
    const getTargetPriceCompanies = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.curatedStocks}?type=consensus_positive_difference`);
        setTargetPricesLastUpdated(result.data.updated_date);
      } catch (error) {
        console.error("Error fetching top companies:", error);

      }
    };

    getTopCompaniesLastUpdated();
    getTopInsiderBuysCompanies();
    getMagicFormulaCompanies();
    getTargetPriceCompanies();
  }, []);

  const collections = [
    {
      title: "Top Scored Stocks",
      description: "Stocks with the highest score according to ValueHunter's fundamental analysis algorithm",
      icon: "hugeicons:medal-first-place",
      path: paths.collections.topStocks,
      disabled: false,
      lastUpdated: topCompaniesLastUpdated,
    },
    {
      title: "Undervalued Stocks According To Analysts",
      description: "Stocks with the biggest positive difference between target analyst price and the current price",
      icon: "mdi:target",
      path: paths.collections.targetPrices,
      disabled: false,
      lastUpdated: targetPricesLastUpdated,
    },
    {
      title: "Top Insider Bought Stocks",
      description: "Stocks with the most insider buying activity in the last 3 months",
      icon: "mdi:account-cash",
      path: paths.collections.insiderBought,
      disabled: false,
      lastUpdated: topInsiderBoughtLastUpdated,
    },
    {
      title: "Magic Formula Stocks",
      description: "Stocks with the highest score using Greenblatt's Magic Formula",
      icon: "mdi:magic-staff",
      path: paths.collections.magicFormula,
      disabled: false,
      lastUpdated: magicCompaniesLastUpdated,
    },

  ];


  const renderCollection = (title: string, description: string, icon: string, path: string, disabled: boolean, lastUpdated: string) => (
    <Stack direction="row"
           spacing={1}
           alignItems="left"
           onClick={() => disabled ? null : navigate(path)}
           sx={{
             width: "100%",
             // add on hover styles
             cursor: disabled ? "not-allowed" : "pointer",
             ...enlargeOnHover
           }}
    >
      <Stack
        sx={{
          backgroundColor: disabled ? theme.palette.grey[600] : theme.palette.primary.main,
          borderRadius: 1, p: 1, width: 70, height: 70
        }}
        alignItems="center" justifyContent="center">
        <Iconify icon={icon} width={60} sx={{color: "white"}}/>
      </Stack>
      <Stack direction="column" spacing={0.5} sx={{width: "100%"}}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" fontWeight="bold"
                      color={disabled ? "text.disabled" : "text.primary"}>{title}</Typography>
          {!disabled && <Label color="info" sx={{fontSize: 11}}>
            <GeneratedOn dateToDisplay={lastUpdated} textVariant="caption" textPrefix="updated" timeAgo sx={{mt: 0}}/>
          </Label>}
          {disabled && <Label color="secondary" sx={{fontSize: 11}}>coming soon</Label>}
        </Stack>

        <Typography variant="body2" color="text.secondary">{description}</Typography>
      </Stack>


    </Stack>
  )

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Stack direction="row" alignItems="center">
      <Button onClick={search.onTrue}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Iconify icon="eva:search-fill" width={30}/>
          <Label sx={{fontSize: 14, color: 'text.secondary', cursor: 'pointer'}}>Click to search</Label>
        </Stack>
      </Button>
    </Stack>
  );

  return (
    <Box sx={{
      mx: {
        xs: 2,
        md: 10,
        lg: 10,
        xl: 30
      },
      pb: isMobile ? 8 : 0, // Add padding to the bottom to avoid content being hidden behind the navigation
    }}>
      <Stack direction="column" alignItems="center" justifyContent="center" spacing={1} sx={{my: 5}}>
        <Stack direction="column" alignItems="left" spacing={5}>
          {/* Collections */}
          <Stack direction="column" alignItems="left" sx={{maxWidth: "800px"}} spacing={2}>
            <Typography variant="h6" sx={{mb: 2}}>Explore our collections of curated stocks</Typography>
            {collections.map((collection) => renderCollection(collection.title, collection.description, collection.icon, collection.path, collection.disabled, collection.lastUpdated))}
          </Stack>


          {/* Search */}
          <Stack direction="column" alignItems="left" spacing={1} sx={{mt: 5}}>
            <Typography variant="h6" sx={{mb: 2}}>Search a company by ticker or name</Typography>
            <CustomSearchbar renderSearchButton={renderSearchButton}/>
          </Stack>

          {/* Screener */}
          <Stack direction="column" alignItems="left" spacing={1} sx={{mt: 5}}>
            <Typography variant="h6" sx={{mb: 2}}>Explore companies by using our screener</Typography>
            <Stack direction="row" alignItems="center">
              <Button
                component={RouterLink}
                href={paths.main.screener}
                sx={{color: "text.secondary"}}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="material-symbols:search-insights" width={30}/>
                  <Label sx={{px: 0.75, fontSize: 14, color: 'text.secondary', cursor: 'pointer'}}>Open screener</Label>
                </Stack>
              </Button>
            </Stack>
          </Stack>


        </Stack>
      </Stack>

      {
        renderSnackbar
      }
    </Box>
  )
    ;
}
