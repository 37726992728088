import Box from "@mui/material/Box";
import React from "react";

export const defaultGrowthScore = 1

export const shouldUseLocalLogo = new Set([
  "AMZN",
  "AAPL",
  "ASML",
  "ADSK",
  "BLK",
  "DDOG",
  "HEI",
  "KMX",
  "MGNI",
  "PLTR",
  "STT",
  "ULTA",
  "MODG"
])

// Component definition.
export default function CompanyLogo({
                                      ticker,
                                      logo_url,
                                      width = 40, // Default value for width
                                      height = 40, // Default value for height
                                      borderRadius = 0, // Default value for border radius
                                    }: {
  ticker: string;
  logo_url: string;
  width?: number; // Optional parameter
  height?: number; // Optional parameter
  borderRadius?: number; // Optional parameter
}) {
  return <Box
    sx={{
      width,
      height,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      backgroundColor: "#ffffff", // Optional background for empty space
      borderRadius,
    }}
  >
    <Box
      component="img"
      src={
        shouldUseLocalLogo.has(ticker)
          ? `../../assets/images/company_logos/${ticker}.png`
          : logo_url
      }
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        borderRadius,
      }}
    />
  </Box>
}

export const currencyToSymbol = {
  "USD": "$",
  "EUR": "€",
  "JPY": "¥",
  "GBP": "£",
  "AUD": "A$",
  "CAD": "C$",
  "CHF": "Fr",
  "CNY": "¥",
  "SEK": "kr",
  "NZD": "NZ$",
  "KRW": "₩",
  "SGD": "S$",
  "NOK": "kr",
  "MXN": "Mex$",
  "INR": "₹",
  "RUB": "₽",
  "ZAR": "R",
  "TRY": "₺",
  "BRL": "R$",
  "TWD": "NT$",
  "DKK": "kr",
  "PLN": "zł",
  "THB": "฿",
  "IDR": "Rp",
  "HUF": "Ft",
  "CZK": "Kč",
  "ILS": "₪",
  "CLP": "CLP$",
  "PHP": "₱",
  "AED": "AED",
  "COP": "COL$",
  "SAR": "SR",
  "MYR": "RM",
  "RON": "L",
  "VND": "₫",
  "NGN": "₦",
  "IQD": "د.ع",
}
