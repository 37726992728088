import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoginRegisterContent from "./login-register-content";


export default function LoginRegisterDialog({dialogOpen, onClose}: { dialogOpen: boolean, onClose: () => void }) {
  return (
    <Dialog open={dialogOpen} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ paddingBottom: 0 }}>Please Login or Sign Up
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{position: 'absolute', right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoginRegisterContent/>
      </DialogContent>
    </Dialog>
  );
}
