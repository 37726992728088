// DataContext.js
import React, {createContext, useState, useEffect} from 'react';
import axios, {endpoints} from "../utils/axios";
import {StaticData} from "../layouts/chat/types";
import {useAuthContext} from "../auth/hooks";


export const StaticDataContext = createContext({} as StaticData);

type Props = {
  children: React.ReactNode;
};

export const StaticDataProvider = ({children}: Props) => {
  const {authenticated} = useAuthContext();
  const [staticData, setData] = useState(
    {highPriorityPredefinedPrompts: [], allPredefinedPrompts: []}
  );

  const fetchData = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.get(endpoints.predefined_prompts, {headers});
      const highPriorityPromptTexts = response.data[0].map((prompt: any) => prompt.prompt);
      const allPromptTexts = response.data[1].map((prompt: any) => prompt.prompt);
      setData({highPriorityPredefinedPrompts: highPriorityPromptTexts, allPredefinedPrompts: allPromptTexts});
    } catch (error) {
      console.error("Error when requesting predefined promts", error);
    }

  };

  useEffect(() => {
    if (authenticated) {
      fetchData();
    }
  }, [authenticated]);

  return (
    <StaticDataContext.Provider value={staticData}>
      {children}
    </StaticDataContext.Provider>
  );
};
