import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {paths} from 'src/routes/paths';
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import {HOST_API} from "../../config-global";


export default function LoginRegisterContent() {
  const handleRegister = () => {
    window.location.href = `${HOST_API}/api/auth/google`;
  };

  const location = useLocation();
  // set returnTo to the current location
  const returnTo = location.pathname + location.search;

  return (
    <Stack direction="column" spacing={2} justifyContent="center" sx={{p: 3}}>
      <Typography variant="body1" mb={2}>
        It is completely free to use ValueHunter, you just need to login or sign up to continue.
      </Typography>
      <Button
        fullWidth
        color="inherit"
        size="medium"
        variant="contained"
        startIcon={<img src="/assets/images/login/google_logo.svg" alt="Google logo"
                        style={{width: '20px', height: '20px'}}/>}
        onClick={handleRegister}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          border: '2px solid black',
          '&:hover': {
            backgroundColor: '#f5f5f5', // Optional: lighter shade for hover effect
          },
        }}
      >
        Sign Up with Google
      </Button>
      <Divider textAlign="center" sx={{my: 1}}>or use your email</Divider>
      <Button
        variant="contained"
        href={`${paths.auth.jwt.login}?returnTo=${returnTo}`}
      >
        Login <LoginIcon sx={{ml: 1, width: 16}}/>
      </Button>
      <Button
        variant="contained"
        href={`${paths.auth.jwt.register}?returnTo=${returnTo}`}
      >
        Sign Up
      </Button>
    </Stack>);
}
