import React, {useEffect, useRef, useState} from "react";
// @mui
import Box from '@mui/material/Box';
import {Collapse, Skeleton} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import {alpha, useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// GA
import ReactGA from "react-ga4";

// lodash

import axios, {endpoints} from 'src/utils/axios';
import {Chat, ChatMany} from "./types";
import PredefinedPrompt from "../../components/predefined-prompt/predefined-prompt";
import ChatPromptHelper from "../../components/chat/chat-prompt-helper/chat-prompt-helper";
import NoCreditsDialog from "../credits/no-credits-dialog";
import Iconify from "../../components/iconify";
import PredefinedPromptItem from "../../components/predefined-prompt-item/predefined-prompt-item";
import {ContextDocument} from "../../components/custom_analysis/context_documents/types";
import ContextDocumentDialog from "../../components/custom_analysis/context_documents/context_document_dialog";
import {useCredits} from "../../providers/credits-provider";
import ChatAIAnswer from "../../components/chat/chat-render-ai-answer/chat-ai-answer";
import {ScoredCompany2} from "../../components/company/types";
import {useAuthContext} from "../../auth/hooks";
import LoginRegisterDialog from "../credits/login-register-dialog";


// ----------------------------------------------------------------------

type ChatChatProps = {
  ticker: string; // used when creating a new chat and chat.ticker is ""
  chat: Chat;
  company: ScoredCompany2;
  setSelectedChat: (chat: Chat) => void;
  contextDocuments: ContextDocument[];
  refreshChats: () => Promise<ChatMany[]>;
  chatLoading: boolean;
}

export default function ChatChat({
                                   ticker,
                                   chat,
                                   company,
                                   setSelectedChat,
                                   contextDocuments,
                                   refreshChats,
                                   chatLoading
                                 }: ChatChatProps) {
  const theme = useTheme();

  const {userCredits, refreshCredits} = useCredits();
  const { authenticated } = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentQuestion, setCurrentQuestion] = useState(''); // State to hold the input value
  const [currentFollowUpQuestions, setCurrentFollowUpQuestions] = useState<string[]>([]);
  const [currentOpenedDocument, setCurrentOpenedDocument] = React.useState<ContextDocument | null>(null);
  const [currentOpenedDocumentStartId, setCurrentOpenedDocumentStartId] = React.useState<string>("");
  const [currentOpenedDocumentEndId, setCurrentOpenedDocumentEndId] = React.useState<string>("");
  const [currentOpenedDocumentSearchTerm, setCurrentOpenedDocumentSearchTerm] = React.useState<string>("");
  const [documentDialogOpen, setDocumentDialogOpen] = React.useState<boolean>(false);
  const [hoveredAnswerParagraphIndices, setHoveredAnswerParagraphIndices] = React.useState<number[]>([]);

  const [openNoCreditsDialog, setOpenNoCreditsDialog] = useState(false); // State to hold the input value
  const [answerLoading, setAnswerLoading] = useState(false); // Add a loading state
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const lastAnswerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [loginRegisterOpen, setLoginRegisterOpen] = useState(false);


  // eslint-disable-next-line consistent-return
  const createChat = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      ticker
    };
    // setLoading(true);
    try {
      const response = await axios.post(`${endpoints.chatNew}`, body, {headers});
      setSelectedChat(response.data);
      return response.data;
      // handleSuccess();
    } catch (error) {
      console.error(error);
      // handleError();
    } finally {
      // setLoading(false);
    }
  };

  // Popover
  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // if (chat.id) {
    //   return;
    // }
    // setAnchorEl(event.currentTarget as HTMLDivElement);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(null);
  };

  // Send question
  const handleEnterPress = (event: React.KeyboardEvent) => {
    // Check if the Enter key is pressed and there is some content in the question
    if (event.key === 'Enter' && currentQuestion.trim() !== '') {
      event.preventDefault(); // Prevent the Enter key from creating a new line
      handleSendClick(currentQuestion); // Send the question
    }
  };

  const sendPredefinedPromptQuestion = async (predefinedQuestion: string) => {
    setCurrentQuestion(predefinedQuestion);
    handleSendClick(predefinedQuestion);
  }

  const sendQuestion = async (question: string) => {
    if (!chat.id) {
      chat = await createChat();
    }
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      user_input: question,
    };

    const newInteraction = {
      human_question: {
        content: question,
      },
      ai_answer: {
        content: '',
      },
      ai_answer_with_sources: {
        content: {
          paragraphs: []
        }
      },
      ai_chart: {
        content: ''
      },
      ai_follow_up: {
        content: ''
      },
    }
    chat.interactions.push(newInteraction);
    setAnswerLoading(true);

    try {
      scrollToBottom();
      const response = await axios.put(`${endpoints.chatNew}/${chat.id}`, body, {headers});
      setCurrentQuestion('');

      // set the AI answer response to the last interaction
      newInteraction.ai_answer.content = response.data.ai_answer.content;
      newInteraction.ai_answer_with_sources.content = response.data.ai_answer_with_sources.content;
      newInteraction.ai_chart.content = response.data.ai_chart.content;
      newInteraction.ai_follow_up = response.data.ai_follow_up;

      // set the chat to update the state
      setSelectedChat({...chat});
      refreshChats();
      refreshCredits();
      console.log("User credits after answer", userCredits);

      const lastAnswerIdx = chat.interactions.length - 1;
      scrollToLastAnswer(lastAnswerIdx);
      scrollToBottom(); // Scroll to the bottom after sending the question
    } catch (error) {
      // Handle any errors
      console.error(error);
    } finally {
      setAnswerLoading(false);
      if (inputRef.current) {
        inputRef.current.focus(); // Focus on the input element
      }
    }
  }

  const handleSendClick = async (question: string) => {
    ReactGA.event({category: 'Button', action: 'Click', label: 'Ask Question'});
    if (question.trim() === '') {
      return;
    }

    if (userCredits.chat_credits <= 0) {
      setOpenNoCreditsDialog(true);
      return;
    }

    await sendQuestion(question);
  };

  const handleClose = () => {
    setOpenNoCreditsDialog(false);
  };

  const scrollToLastAnswer = (lastAnswerIdx: number) => {
    const lastAnswerId = `ai-answer-${lastAnswerIdx}`;
    const lastAnswerElement = document.getElementById(lastAnswerId);
    setTimeout(() => {
      if (lastAnswerElement) {
        lastAnswerElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }, 100);
  };


  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        const scrollElement = chatContainerRef.current;
        scrollElement.scrollTo({top: scrollElement.scrollHeight, behavior: 'smooth'});
      }
    }, 100);
  };

useEffect(() => {
  if (chat.share) {
    const answerIndex: number | null = chat.share?.selected_content?.answer_index;
    const selectedText: string | null = chat.share?.selected_content?.selected_text;

    if (answerIndex !== null && selectedText) {
      const answerId = `ai-answer-${answerIndex}`;
      const answerElement = document.getElementById(answerId);

      if (answerElement) {
        answerElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // Helper function to get all text nodes within an element
        const getTextNodes = (node: Node): Text[] => {
          let textNodes: Text[] = [];

          node.childNodes.forEach((child: ChildNode) => {
            if (child.nodeType === Node.TEXT_NODE) {
              textNodes.push(child as Text);
            } else if (child.nodeType === Node.ELEMENT_NODE) {
              textNodes = textNodes.concat(getTextNodes(child));
            }
          });

          return textNodes;
        };

        // Get all text nodes
        const textNodes: Text[] = getTextNodes(answerElement);

        // Combine all text to find the selected text's position
        const fullText: string = textNodes.map((node) => node.textContent).join('');
        const startIndex: number = fullText.indexOf(selectedText);
        const endIndex: number = startIndex + selectedText.length;

        if (startIndex !== -1) {
          let currentPos = 0;

          textNodes.forEach((node: Text) => {
            const nodeText: string | null = node.textContent;
            if (nodeText === null) return;

            const nodeEnd = currentPos + nodeText.length;

            if (nodeEnd > startIndex && currentPos < endIndex) {
              const relativeStart = Math.max(0, startIndex - currentPos);
              const relativeEnd = Math.min(nodeText.length, endIndex - currentPos);

              const before = nodeText.slice(0, relativeStart);
              const highlight = nodeText.slice(relativeStart, relativeEnd);
              const after = nodeText.slice(relativeEnd);

              const span = document.createElement('span');
              span.innerHTML = `${before}<mark style="background-color: yellow;">${highlight}</mark>${after}`;

              if (node.parentNode) {
                node.parentNode.replaceChild(span, node);
              }
            }

            currentPos = nodeEnd;
          });
        }
      }
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [chat.share]);;


  useEffect(() => {
    // refresh credits initially
    refreshCredits();
    console.log("User credits init", userCredits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chat.interactions[chat.interactions.length - 1]?.ai_follow_up?.content) {
      try {
        const {questions} = JSON.parse(chat.interactions[chat.interactions.length - 1]?.ai_follow_up?.content);
        setCurrentFollowUpQuestions(questions);
      } catch (e) {
        console.error("Error parsing follow-up questions", e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, chat.interactions]);

  const MAX_CHAR_LIMIT = 500; // Define the max character limit
  const renderQuestionInputDesktop = (
    <Box>
      <Stack
        className="question-input"
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        sx={{
          ml: "50px",
          py: 1,
          height: "70px",
          flexShrink: 0,
        }}
      >
        <InputBase
          multiline
          fullWidth
          rows={2}
          placeholder={`What are the main risks for ${ticker}?`}
          value={currentQuestion}
          onChange={(e) => {
            setCurrentQuestion(e.target.value);
          }}
          onKeyDown={handleEnterPress} // Listen for key press events
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          disabled={answerLoading}
          sx={{
            p: 1.5,
            borderRadius: 1,
            border: (th) => `solid 2px ${alpha(th.palette.grey[500], 0.2)}`,
            '&:focus-within': {
              borderColor: (th) => th.palette.grey[600],
            },
          }}
          inputRef={inputRef}
        />
        <Button variant="contained" onMouseDown={() => handleSendClick(currentQuestion)} sx={{p: 2.6}}
                disabled={currentQuestion === '' || !currentQuestion || answerLoading || currentQuestion.length >= MAX_CHAR_LIMIT}
        >
          {/* Ask Button */}
          <Iconify icon="eva:paper-plane-fill" width={24}/>
        </Button>
        {/* PromptHelper */}
        {!isInputFocused && authenticated && (
          <ChatPromptHelper sendQuestion={sendPredefinedPromptQuestion}
                            disabled={answerLoading || userCredits.chat_credits < 0}/>
        )}
      </Stack>

      <Typography
        variant="caption"
        sx={{mt: 1, ml: 6.3, color: currentQuestion.length >= MAX_CHAR_LIMIT ? 'error.main' : 'text.secondary'}}
      >
        {currentQuestion.length}/{MAX_CHAR_LIMIT} characters
      </Typography>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{pointerEvents: 'none'}}
      >
        <Box p={2}>
          <Typography variant="body2">Create a chat (top left) before asking a question.</Typography>
        </Box>
      </Popover>
    </Box>
  );

  const renderQuestionInputMobile = (
    <Stack
      className="question-input"
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{
        py: 1,
        px: 1,
        height: "70px",
        flexShrink: 0,
        // borderTop: `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
      }}
    >
      {!isInputFocused && authenticated && (
        <ChatPromptHelper sendQuestion={sendPredefinedPromptQuestion}
                          disabled={answerLoading || userCredits.chat_credits < 0}/>
      )}
      <InputBase
        multiline
        fullWidth
        maxRows={2}
        placeholder="Ask me a question"
        value={currentQuestion}
        onChange={(e) => {
          setCurrentQuestion(e.target.value);
        }}
        onKeyDown={handleEnterPress} // Listen for key press events
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        disabled={answerLoading}
        // this is needed to prevent zooming on mobile
        inputProps={{style: {fontSize: 16}}}
        sx={{
          p: 1,
          borderRadius: 1,
          border: (th) => `solid 2px ${alpha(th.palette.grey[500], 0.2)}`,
          '&:focus-within': {
            borderColor: (th) => th.palette.grey[600],
          },
        }}
        inputRef={inputRef}
      />
      <Button variant="contained" onClick={() => handleSendClick(currentQuestion)}
              disabled={currentQuestion === '' || !currentQuestion || answerLoading || currentQuestion.length >= MAX_CHAR_LIMIT}>
        <Iconify icon="eva:paper-plane-fill" width={20}/>
      </Button>
    </Stack>
  );


  const customScrollbarStyles = {
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[600]
    },
  };
  const renderHumanQuestion = (key: any, question: string) => (
    <Stack key={`human-question-${key}`} direction="row" spacing={2}>

      <Avatar sx={{height: {xs: 25, lg: 35}, width: {xs: 25, lg: 35}}} alt="avatar" src="/assets/avatars/avatar_5.jpg"/>
      <Paper
        sx={{
          py: {xs: 0.5, lg: 1.5},
          px: 1.5,
          flexGrow: 1,
          bgcolor: 'background.paper',
        }}
      >
        <Stack
          sx={{mb: 0.5}}
          alignItems={{sm: 'center'}}
          justifyContent="space-between"
          direction={{xs: 'column', sm: 'row'}}
        >
          <Box sx={{typography: 'subtitle2'}}>You</Box>
        </Stack>

        <Box
          sx={{
            typography: 'body2',
            color: theme.palette.grey[600],
            whiteSpace: "pre-wrap"
          }}>{question}</Box>
      </Paper>
    </Stack>
  )

  const onSourceClick = (src: any) => {
    if (!authenticated) {
      setLoginRegisterOpen(true);
      return;
    }

    const document = contextDocuments.find((doc) => doc.type === src.metadata.document_type);
    if (!document) {
      return;
    }
    setCurrentOpenedDocument(document);
    setDocumentDialogOpen(true);
    setCurrentOpenedDocumentStartId(src.metadata.source_id_start);
    setCurrentOpenedDocumentEndId(src.metadata.source_id_end);
    setCurrentOpenedDocumentSearchTerm(src.page_content);
  };

  const renderAIAnswerLoading = () => (
    <Stack direction="row" spacing={2}>
      <Avatar sx={{height: 35, width: 35}} alt="logo"
              src="/assets/images/logo/logo_without_text.png"/>
      <Paper
        sx={{
          pt: 1,
          pb: 0.5,
          px: 2,
          bgcolor: 'background.neutral',
        }}
      >
        <Iconify icon="svg-spinners:3-dots-scale-middle" width={24}/>
      </Paper>
    </Stack>
  )

  const [showFollowUpQuestions, setShowFollowUpQuestions] = useState(false);

  useEffect(() => {
    if (showFollowUpQuestions) {
      scrollToBottom();
    }
  }, [showFollowUpQuestions]);

  const renderFollowUpQuestions = () => (
    <Stack sx={{ml: "48px"}} spacing={1}>
      {showFollowUpQuestions ? (
        <Button onClick={() => setShowFollowUpQuestions(!showFollowUpQuestions)}>
          Follow-up questions <ExpandLessIcon/>
        </Button>) : (
        <Button onClick={() => {
          setShowFollowUpQuestions(true)
        }}>
          Follow-up questions <ExpandMoreIcon/>
        </Button>
      )}
      <Collapse in={showFollowUpQuestions}>
        <Stack spacing={2}>
          {currentFollowUpQuestions.map((question: string, index: number) => (
            <PredefinedPromptItem
              key={index}
              sendQuestion={handleSendClick}
              promptText={question}
              disabledButton={false}
              setDisabledButtons={() => {
              }}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );


  const renderChatContent = (localChat: Chat) => (
    <Stack
      spacing={1.5}
      ref={chatContainerRef}
      justifyContent={localChat?.interactions?.length === 0 ? 'flex-end' : 'flex-start'}
      sx={{
        flexGrow: 1, // Allows the Stack to grow and fill available space
        overflowY: 'auto', // Enables scrolling only when content overflows
        px: {xs: 1, lg: 3},
        pb: 2,
        ...customScrollbarStyles
      }}>
      {localChat?.interactions?.length === 0 && (
        <PredefinedPrompt sendQuestion={sendPredefinedPromptQuestion}/>
      )}
      {localChat?.interactions && (
        localChat.interactions.map((interaction, index) => (
            <Box key={`interaction-${index}`}>
              {/* Human */}
              {interaction.human_question.content && renderHumanQuestion(index, interaction.human_question.content)}
              {/* AI */}
              <ChatAIAnswer
                key={index}
                chatId={localChat.id}
                answerIndex={index}
                interaction={interaction}
                onSourceClick={onSourceClick}
                hoveredAnswerParagraphIndices={hoveredAnswerParagraphIndices}
                setHoveredAnswerParagraphIndices={setHoveredAnswerParagraphIndices}
              />
            </Box>
          )
        )
      )
      }

      {/*
      */}
      {!answerLoading && localChat.interactions[localChat.interactions.length - 1]?.ai_follow_up?.content && (
        renderFollowUpQuestions()
      )}
      {/* AI Loading */}
      {answerLoading && renderAIAnswerLoading()}
    </Stack>
  )


  const renderNoCreditsDialog = (
    <NoCreditsDialog open={openNoCreditsDialog} onClose={handleClose}/>
  )

  if (chatLoading) {
    return (<Box
      sx={{
        height: '100%',
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1000px', // Add this line
        margin: '0 auto', // Center the Box horizontally
        p: 2,
      }}
    >
      <Skeleton variant="text" height={50}/>
      <Skeleton height={200}/>
      <Skeleton variant="text" height={50}/>
      <Skeleton height={300}/>
      <Skeleton variant="text" height={50}/>
      <Skeleton height="100px"/>
      <Skeleton variant="text" height={50}/>
      <Skeleton height="70%"/>
    </Box>);
  }

  return (
    <Box
      sx={{
        py: 1,
        // This ensures ChatChat itself fills its parent’s space (mobile only)
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1000px', // Add this line
        margin: '0 auto', // Center the Box horizontally
      }}
    >
      {renderChatContent(chat)}
      {isMobile ? (renderQuestionInputMobile) : (renderQuestionInputDesktop)}
      {renderNoCreditsDialog}
      {currentOpenedDocument && (
        <ContextDocumentDialog
          content={currentOpenedDocument?.type === "PRESS-RELEASE" ? currentOpenedDocument?.annotatedContent.toString() : currentOpenedDocument?.annotatedContent}
          searchTerm={currentOpenedDocumentSearchTerm}
          url={currentOpenedDocument?.url}
          date={new Date(currentOpenedDocument?.documentDate || "").toLocaleDateString()}
          company={company}
          documentType={currentOpenedDocument?.type}
          dialogOpen={documentDialogOpen}
          setDialogOpen={setDocumentDialogOpen}
          startElementId={currentOpenedDocumentStartId}
          endElementId={currentOpenedDocumentEndId}
        />
      )}
      <LoginRegisterDialog dialogOpen={loginRegisterOpen} onClose={() => setLoginRegisterOpen(false)} />

    </Box>
  );
}
