import React from "react";

import EarningsDialog from "../../earnings_call_analysis/earnings_dialog";
import EdgarDocumentPreviewDialog from "./edgar_document_preview_dialog";
import {ScoredCompany2} from "../../company/types";
import RatiosPreviewDialog from "./ratios_review_dialog";
import FinancialsPreviewDialog from "./financials_preview_dialog";

export default function ContextDocumentDialog({
                                                content,
                                                searchTerm,
                                                url,
                                                date,
                                                company,
                                                documentType,
                                                dialogOpen,
                                                setDialogOpen,
                                                startElementId,
                                                endElementId,
                                              }: {
  content: string | string[];
  searchTerm?: string;
  url: string;
  date: string;
  company?: ScoredCompany2;
  documentType?: "10-K" | "10-Q" | "EARNINGS-CALL" | "PRESS-RELEASE" | "RATIOS" | "GROWTH" | "FINANCIALS";
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  startElementId?: string;
  endElementId?: string;
}) {
  if (!content) {
    return null;
  }

  if (documentType === "EARNINGS-CALL") {
    return (
      <EarningsDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        paragraphs={content as string[]}
        searchString={searchTerm?.slice(0, -1) || ""}
        date={date}
        quarter=""
      />
    );
  }
  if ((documentType === "RATIOS" || documentType === "GROWTH") && company) {
    return <RatiosPreviewDialog company={company} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />;
  }
  if ((documentType === "FINANCIALS") && company) {
    return <FinancialsPreviewDialog company={company} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />;
  }

  return (
    <EdgarDocumentPreviewDialog
      content={content}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      url={url}
      date={date}
      documentType={documentType}
      startElementId={startElementId}
      endElementId={endElementId}
      searchString={searchTerm?.slice(0, -1) || ""}
    />
  );
}
