import React from "react";
import {Box, Button, Typography} from '@mui/material';
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import {useAuthContext} from "../../auth/hooks";
import {paths} from '../../routes/paths';
import {getUserType, UserTypes} from "../company/utils";

export function BecomePremiumToAccessContent({ticker, premiumOnly}: {
  ticker: string | undefined,
  premiumOnly?: boolean
}) {
  const {user} = useAuthContext();
  const handleMailto = () => {
    window.location.href = 'mailto:contact@valuehunter.net';
  };

  const renderVisitedCompanies = () => (
    user?.user_visited_companies.length > 0 &&
    <Typography variant="body2" align="center" sx={{
      mt: 1, backgroundColor: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(3px)",
    }}>
      You can still access the full analyses of the following
      companies that you have already opened:
      <br/>
      <Box component="span"
           sx={{backgroundColor: "rgba(255, 255, 255, 0.3)", backdropFilter: "blur(3px)",}}>
        {user?.user_visited_companies.map((company: string, index: number) => (

          <Link key={index} href={`/companies/${company}`}>
            {company}
          </Link>
        )).reduce((prev: any, curr: any) => prev === null ? [curr] : [prev, ', ', curr], null)}
      </Box>
    </Typography>
  )

  const renderFreeNoCredits = () => (
    <Stack direction="column" spacing={1}>
      <Typography variant="body2" textAlign="center">
        You have run out of credits. Start a 10-day free trial to get more credits.
      </Typography>
      <Stack direction="row" spacing={2} alignSelf="center" sx={{my: 1}}>
        <Button
          variant="contained"
          href={paths.main.plans}
        >
          Start Free Trial 🚀
        </Button>
      </Stack>
      {renderVisitedCompanies()}
    </Stack>
  );

  const renderPremiumNoCredits = () => (
    <Stack direction="column" spacing={1}>
      {user?.subscription?.status === 'active' && (
        <Typography variant="body2" align="center">
          You have reached your credit limit. Please drop an email to <Link
          onClick={handleMailto} sx={{cursor: "pointer"}}>contact@valuehunter.net</Link> to get
          more
          credits.
        </Typography>
      )}
      {user?.subscription?.status === 'trialing' && (
        <Typography variant="body2" align="center">
          You are currently in <strong>free trial</strong> and you have reached your credit limit.
          Your credits will be renewed after the trial period ends
          on <strong>{new Date(user?.subscription?.next_renewal).toLocaleDateString()}</strong>.
          If you {`can't`} wait drop an email to <Link
          onClick={handleMailto} sx={{cursor: "pointer"}}>contact@valuehunter.net</Link> to get
          more
          credits.
        </Typography>
      )}
      {renderVisitedCompanies()}
    </Stack>
  );

  const renderPremiumOnlyFeature = () => (
    <Stack direction="column" spacing={1}>
      <Typography variant="body2" textAlign="center">
        This feature is for Premium users only.
      </Typography>
      <Typography variant="body2" textAlign="center">
        Start a 10-day free trial access it.
      </Typography>
      <Stack direction="row" spacing={2} alignSelf="center" sx={{my: 1}}>
        <Button
          variant="contained"
          href={paths.main.plans}
        >
          Start Free Trial 🚀
        </Button>
      </Stack>
    </Stack>
  )

  const renderContent = () => {
    const userType = getUserType(user, ticker || "");
    if (premiumOnly && ![UserTypes.PremiumNoCredits, UserTypes.PremiumWithCredits].includes(userType)) {
        return renderPremiumOnlyFeature();
    }

    if (userType === UserTypes.FreeNoCredits) {
      return renderFreeNoCredits();
    }
    if (userType === UserTypes.PremiumNoCredits) {
      return renderPremiumNoCredits();
    }

    return null;
  }

  return renderContent();
}
