// @mui
import React, {useState} from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Popover from "@mui/material/Popover";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import CardHeader from "@mui/material/CardHeader";


import {
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import FeaturesPreview from "src/layouts/features/features-preview";
import Iconify from "../../components/iconify";
import BecomePremiumButton from "./become-premium-button";
import {featureDescriptions} from "../../utils/constants";
import {gaViewProduct} from "../../utils/ga";

// ----------------------------------------------------------------------

function createData(
  feature: string,
  featureDescription: string,
  freePlan: string,
  premiumPlan: string,
) {
  return {feature, featureDescription, freePlan, premiumPlan};
}

const rows = [
  createData('Scoring Algorithm', featureDescriptions.scoringAlgorithm, 'yes', 'yes'),
  createData('Screener', featureDescriptions.screener, 'yes', 'yes'),
  createData('Financial Statements', featureDescriptions.financialStatementsPricing, 'yes', 'yes'),
  createData('Watchlist', featureDescriptions.watchlist, 'yes', 'yes'),
  createData('Markets Overview', featureDescriptions.marketsOverview, 'yes', 'yes'),
  createData('Top Companies', featureDescriptions.topCompanies, 'yes', 'yes'),
  // createData('Latest News Analysis', featureDescriptions.latestNews, 'no', "yes"),
  // createData('Valuation Analysis', featureDescriptions.valuation, 'no', "yes"),
  // createData('Business Model Analysis', featureDescriptions.businessModel, 'no', "yes"),
  // createData('Growth Potential Hypothesis', featureDescriptions.growthPotential, 'no', "yes"),
  // createData('Financial Strength Analysis', featureDescriptions.financialStrength, 'no', "yes"),
  createData('Full company analysis', featureDescriptions.comprehensiveAnalysis, '3/week', "50/week"),
  createData('Copilot prompts', featureDescriptions.comprehensiveAnalysis, '3/week', "100/week"),
];

const glowEffect = {
  boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)', // Start with red for the example
  animation: 'glow 8s infinite linear',
  '@keyframes glow': {
    '0%': {
      boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)', // Red
    },
    '25%': {
      boxShadow: '0 0 10px rgba(0, 255, 0, 0.5)', // Green
    },
    '50%': {
      boxShadow: '0 0 10px rgba(0, 0, 255, 0.5)', // Blue
    },
    '75%': {
      boxShadow: '0 0 10px rgba(255, 255, 0, 0.5)', // Yellow
    },
    '100%': {
      boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)', // Back to Red
    },
  }
}

export default function PricingView() {
  gaViewProduct()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };
  const handleMailto = () => {
    window.location.href = 'mailto:contact@valuehunter.net?subject=Hi, I have a question.';
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  const hoverStyles = {
    "&:hover": {
      cursor: "help",
    },
  }
  const renderPlansCards = () => (
    <Grid container spacing={3} sx={{my: 5}}>
      {/* Free Plan */}
      <Grid item xs={12} md={6}>
        <Card sx={{
          height: '100%', boxShadow: theme.customShadows.z16,
          background: `linear-gradient(to right, #FFFFFF, #F9FAFB)`
        }}>
          <CardHeader title="FREE" subheader="Get value with limited features"
                      sx={{textAlign: "center"}}/>
          <CardContent>
            <Divider/>
            <Box>
              <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography variant="body1" sx={{mt: 2}}>
                  <Typography variant="h3" component="span" sx={{fontSize: 24, fontWeight: 700}}>
                    $0
                  </Typography>
                  /mo
                </Typography>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body1">Scoring Algorithm</Typography>
                  <Typography variant="body1">Screener</Typography>
                  <Typography variant="body1">Financial Statements</Typography>
                </Stack>
                <Button variant="outlined" sx={{mt: 2, width: '60%', py: 1.5}}
                        onClick={() => navigate("/dashboard")}>Get Started</Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* Premium Plan */}
      <Grid item xs={12} md={6}>
        <Card sx={{
          height: '100%',
          background: `linear-gradient(to right, #FFFFFF, #F4F6F8)`,
          ...glowEffect
        }}>
          <CardHeader title="PREMIUM" subheader="Analyse and get alpha like a pro"
                      sx={{textAlign: "center"}}/>
          <CardContent>
            <Divider/>
            <Box>
              <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography variant="body1" sx={{mt: 2}}>
                  <Typography variant="h3" component="span" sx={{fontSize: 24, fontWeight: 700}}>
                    $9.99
                  </Typography>
                  /mo
                </Typography>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body1">All from free plan</Typography>
                  <Typography variant="body1">In-depth AI Analysis</Typography>
                  <Typography variant="body1">News Analysis</Typography>
                </Stack>

                <BecomePremiumButton/> {/* This is the button that will be disabled if the user is already premium */}

              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}/>
    </Grid>
  );

  const renderDesktopTable = () => (
    <Box sx={{mb: 2}}>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell align="center">FREE</TableCell>
              <TableCell align="center">PREMIUM 🚀</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.feature}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row"
                           onMouseEnter={(e) => handlePopoverOpen(e, row.featureDescription)}
                           onMouseLeave={handlePopoverClose}
                           sx={{...hoverStyles}}

                >
                  {row.feature} <Iconify icon="material-symbols:help" width={20} sx={{ml: 0.5}}/>
                </TableCell>
                <TableCell align="center">
                  {row.freePlan === 'yes' && <CheckIcon color="primary"/>}
                  {row.freePlan === 'no' && <CloseIcon color="disabled"/>}
                  {!['yes', 'no'].includes(row.freePlan) &&
                    <Typography variant="subtitle2" color="text.secondary">{row.freePlan}</Typography>}
                </TableCell>
                <TableCell align="center">
                  {row.premiumPlan === 'yes' && <CheckIcon color="primary"/>}
                  {row.premiumPlan === 'no' && <CloseIcon color="disabled"/>}
                  {!['yes', 'no'].includes(row.premiumPlan) &&
                    <Typography variant="subtitle2">{row.premiumPlan}</Typography>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderMobileTable = () => (
    <Box>
      {rows.map((row) => (
        <TableContainer component={Paper} key={row.feature}
                        sx={{mt: 1, mb: 2, boxShadow: theme.customShadows.z4}}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left"
                           onMouseEnter={(e) => handlePopoverOpen(e, row.featureDescription)}
                           onMouseLeave={handlePopoverClose}
                           sx={{...hoverStyles}}
                >{row.feature} <Iconify icon="material-symbols:help" width={20} sx={{ml: 0.5}}/>
                </TableCell>
                <TableCell align="left"/>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="freePlan">
                <TableCell align="left">Free</TableCell>
                <TableCell align="right">
                  {row.freePlan === 'yes' && <CheckIcon color="primary"/>}
                  {row.freePlan === 'no' && <CloseIcon color="error"/>}
                  {!['yes', 'no'].includes(row.freePlan) &&
                    <Typography variant="subtitle2">{row.freePlan}</Typography>}
                </TableCell>
              </TableRow>
              <TableRow key="premiumPlan">
                <TableCell align="left">Premium &nbsp; 🚀</TableCell>
                <TableCell align="right">
                  {row.premiumPlan === 'yes' && <CheckIcon color="primary"/>}
                  {row.premiumPlan === 'no' && <CloseIcon color="error"/>}
                  {!['yes', 'no'].includes(row.premiumPlan) &&
                    <Typography variant="subtitle2">{row.premiumPlan}</Typography>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </Box>
  );

  return (
    <Container
      sx={{
        pt: 5,
        pb: 5,
        minHeight: 1,
      }}
    >
      <Button onClick={() => navigate("/")}>
        <ArrowBackIosIcon/>
      </Button>
      <Typography variant="h4" align="center" sx={{mb: 4}}>
        Try ValueHunter Premium without risk
      </Typography>
      <Typography variant="body1" align="center">
        <strong>10-day</strong> free trial
      </Typography>
      <Typography variant="body2" align="center" color="text.secondary">
        We {`won't`} charge you until the trial ends. Cancel anytime.
      </Typography>
      <Typography variant="body1" align="center" sx={{mt: 2}}>
        <strong>30-day</strong> money-back guarantee
      </Typography>
      <Typography variant="body2" align="center" color="text.secondary">
        If you {`don't`} like it, {`we'll`} refund your money. No questions asked.
      </Typography>
      <Box sx={{m: 2}}>
        {renderPlansCards()}
      </Box>

      <Box sx={{m: 2}}>
        <Typography variant="h4" align="center" sx={{mb: 2}}> Compare Plans </Typography>
        {isMobile ? renderMobileTable() : renderDesktopTable()}
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
          onClose={handlePopoverClose}
          disableRestoreFocus
          disableScrollLock
          sx={{pointerEvents: 'none'}}
          slotProps={{
            paper: {style: {width: 250}},
          }}
        >
          <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
            {popoverContent}
          </Typography>
        </Popover>
      </Box>

      {/* Features */}
      <Typography variant="h4" align="center" sx={{mt: 5, mb: 2}}>
        Features
      </Typography>
      <FeaturesPreview/>

      <Stack direction="column" spacing={3} alignItems="center">
        <Typography variant="h5" align="center" sx={{mt: 5}}>
          Have questions? Send us an email!
        </Typography>
        <Button variant="contained" onClick={handleMailto}>
          Contact Us <Iconify width={16} icon="eva:external-link-fill" sx={{ml: 1}}/>
        </Button>
      </Stack>
    </Container>
  );
}
