import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";

import CompanyValuationHistoricalChart, {HistoricalData} from "./company-valuation-historical-chart";
import CompanyValuationPeersComparison, {PeersData} from "./company-valuation-peers-comparison";
import axios, {endpoints} from "../../utils/axios";
import CompanyValuationIntrinsicChart from "./company-valuation-intrinsic-chart";
import CompanyValuationRatiosScoring, {RelativeValuationData} from "./company-valuation-ratios-scoring";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  sector: string | undefined;
  currencySymbol: string;
  setAiAnalysesReady?: (key: string) => void | undefined;
}

interface ValuationData {
  historical_ratios: HistoricalData,
  dcf: {
    dcf_price: number;
    stock_price: number;
    free_cash_flow: {
      actual_values: {
        year: string,
        value: number
      }[],
      actual_percentage_change: number,
      predicted_values: {
        year: string,
        value: number
      }[]
      predicted_percentage_change: number
    }
  },
  peers: PeersData;
  relative_valuation: RelativeValuationData;
}

export default function CompanyValuation({ticker, sector, currencySymbol, setAiAnalysesReady}: CompanyDocumentsProps) {
  const theme = useTheme();

  const [valuationData, setValuationData] = useState<ValuationData>({
    historical_ratios: {
      priceToEarnings: {mean: 0, historical: []},
      priceToBook: {mean: 0, historical: []},
      priceToSales: {mean: 0, historical: []},
      priceToFreeCashFlows: {mean: 0, historical: []},
      priceEarningsToGrowth: {mean: 0, historical: []}
    },
    dcf: {
      dcf_price: 0, stock_price: 0,
      free_cash_flow: {
        "actual_values": [{year: "", value: 0}],
        "actual_percentage_change": 0,
        "predicted_values": [{year: "", value: 0}],
        "predicted_percentage_change": 0
      }
    },
    peers: {
      priceToEarnings: {mean: 0, peers: []},
      priceToBook: {mean: 0, peers: []},
      priceToSales: {mean: 0, peers: []},
      priceToFreeCashFlows: {mean: 0, peers: []},
      priceEarningsToGrowth: {mean: 0, peers: []}
    },
    relative_valuation: {
      price_to_earnings: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      price_to_book: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      price_to_free_cashflow: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      ev_to_ebitda: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      },
      peg: {
        value: 0,
        vh_score: 0,
        relative_score: 0,
        relative_percentage: 0,
        total_companies: 0
      }
    }
  });
  const [valuationAnalysis, setValuationAnalysis] = useState<any>({});
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);

  useEffect(() => {
    const getCompanyValuation = async () => {
      try {
        const valuationMetricsResponse = await axios.get<any>(`/api/companies/valuation/v2/${ticker}`);
        console.log("Valuation metrics response:", valuationMetricsResponse.data);
        setValuationData(valuationMetricsResponse.data);
      } catch (error) {
        console.error("Error fetching company valuation:", error);
      }
    }

    const getCompanyValuationAnalysis = async () => {
      try {
        setIsLoadingAnalysis(true);
        const valuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=valuation`);
        setValuationAnalysis(JSON.parse(valuationAnalysisResponse.data.answer));
        setAnalysisDatetimeGenerated(valuationAnalysisResponse.data.datetime_generated);
        setIsLoadingAnalysis(false);

        if (!valuationAnalysisResponse.data.is_latest) {
          setIsLoadingAnalysisLatest(true);
          const latestValuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=valuation&return_latest=true`);
          setValuationAnalysis(JSON.parse(latestValuationAnalysisResponse.data.answer));
          setAnalysisDatetimeGenerated(latestValuationAnalysisResponse.data.datetime_generated);
          setIsLoadingAnalysisLatest(false);
        }

      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('valuation');
        }
      }
    }

    getCompanyValuation();
    // getCompanyValuationAnalysis();  // TODO: stop this while the AskNews access is resolved
  }, [ticker, setAiAnalysesReady]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
        {valuationData.dcf && (
          <CompanyValuationIntrinsicChart ticker={ticker || ""} dcfData={valuationData.dcf}
                                          currencySymbol={currencySymbol}/>
        )}
      </Grid>
      <Grid item xs={12} md={6} xl={6} sx={{mb: 1}}>
        <Stack direction="column" spacing={2}>
          <CompanyValuationRatiosScoring sector={sector} relativeValuation={valuationData.relative_valuation}/>

          {valuationData.historical_ratios && (
            <CompanyValuationHistoricalChart
              priceToEarnings={valuationData.historical_ratios.priceToEarnings}
              priceToBook={valuationData.historical_ratios.priceToBook}
              priceToSales={valuationData.historical_ratios.priceToSales}
              priceToFreeCashFlows={valuationData.historical_ratios.priceToFreeCashFlows}
              priceEarningsToGrowth={valuationData.historical_ratios.priceEarningsToGrowth}
            />
          )}

          {valuationData?.peers && valuationData.peers?.priceToEarnings?.peers && (
            <CompanyValuationPeersComparison ticker={ticker} peersData={valuationData.peers}/>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
