import {currencyToSymbol} from "./constants";
import {fPercent, fShortenNumber} from "../../utils/format-number";
import {
  dividendYieldExplanation,
  evEbitdaExplanation,
  pbExplanation,
  peExplanation,
  pegExplanation,
  pfcfExplanation,
  psExplanation
} from "../../common/texts";
import {ScoredCompany2} from "./types";

// @ts-ignore
export const getCurrencySymbol = (currency: string) => currencyToSymbol[currency] || currency

export const scrollToElement = (locationHash: { hash: string }, block: string = "center", runAnimation: boolean = true, callback?: () => void) => {
  const {hash} = locationHash;
  if (hash) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          observer.disconnect();
          if (runAnimation) {
            element.animate([
              {transform: 'scale(1)'},
              {transform: 'scale(1.02)'},
              {transform: 'scale(1)'},
            ], {
              duration: 700,
              iterations: 1,
            });
          }
          if (callback) callback();
        }
      }, {threshold: 1.0});

      observer.observe(element);

      element.scrollIntoView({
        behavior: 'smooth',
        // @ts-ignore
        block
      });
    } else {
      console.error('Element not found with id', id);
    }
  }
};

export const getProgressBarColor = (value: number) => {
  // Map the score to a color gradient from red to light green
  const red = Math.max(255 - (value * 2.55), 0); // Closer to 0, more red
  const green = Math.min(value * 2.55, 200); // Closer to 100, lighter green (adjust to your preference)
  // Convert RGB values to a CSS color string
  return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, 0)`;
};

export enum UserTypes {

  PremiumWithCredits = 'premium_with_credit',
  PremiumNoCredits = 'premium_no_credits',
  FreeWithCredits = 'free_with_credits',
  FreeNoCredits = 'free_no_credits',
  Anonymous = 'anonymous',
}

export const getUserType = (user: any, ticker: string | undefined) => {
  if (!user) {
    return UserTypes.Anonymous;
  }

  if (!user?.subscription || !user.subscription.is_active) {
    // free user
    if (user?.user_credits.credits <= 0 && (!user?.user_visited_companies.includes(ticker))) {
      return UserTypes.FreeNoCredits;
    }
    return UserTypes.FreeWithCredits;
  }
  if (user?.subscription && user?.subscription.is_active) {
    if (user?.user_credits.credits <= 0 && (!user?.user_visited_companies.includes(ticker))) {
      return UserTypes.PremiumNoCredits;
    }
    return UserTypes.PremiumWithCredits;
  }
  return UserTypes.Anonymous;
};

export const isPremiumUser = (user: any) => user?.subscription && user.subscription.is_active


export const getQuickNumbersForCompany = (company: ScoredCompany2) => ({
    valuation: [
      {
        key: 'P/E Ratio',
        value: company.quick_numbers.valuation.price_earnings_ratio,
        formatter: fShortenNumber,
        helpText: peExplanation,
      },
      {
        key: 'P/B Ratio',
        value: company.quick_numbers.valuation.price_to_book_ratio,
        formatter: fShortenNumber,
        helpText: pbExplanation,
      },
      {
        key: 'P/S Ratio',
        value: company.quick_numbers.valuation.price_to_sales_ratio,
        formatter: fShortenNumber,
        helpText: psExplanation,

      },
      {
        key: 'P/FCF Ratio',
        value: company.quick_numbers.valuation.price_to_free_cash_flows_ratio,
        formatter: fShortenNumber,
        helpText: pfcfExplanation,

      },
      {
        key: 'EV/EBITDA',
        value: company.quick_numbers.valuation.ev_to_ebitda,
        formatter: fShortenNumber,
        helpText: evEbitdaExplanation,
      },
      {
        key: 'PEG Ratio',
        value: company.quick_numbers.valuation.price_to_earnings_growth_ratio,
        formatter: fShortenNumber,
        helpText: pegExplanation,
      },
      {
        key: 'Dividend Yield',
        value: company.quick_numbers.dividend_yield_percentage,
        formatter: fPercent,
        helpText: dividendYieldExplanation,

      },
    ],
    solvency: [
      {
        key: 'Debt/Equity',
        value: company.quick_numbers.solvency.debt_to_equity_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of equity and debt used to finance a company\'s assets. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Interest Coverage Ratio',
        value: company.quick_numbers.solvency.interest_coverage_ratio,
        formatter: fShortenNumber,
        helpText: 'Indicates a company\'s ability to meet interest payments on its debt. Higher values suggest lower risk of default, while lower values may indicate financial stress. Value of at least 1.5 is desirable.',
      },
      {
        key: 'Cashflow/Debt',
        value: company.quick_numbers.solvency.cashflow_to_debt_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay off its debt using its operating cash flow. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
      {
        key: 'Debt Ratio',
        value: company.quick_numbers.solvency.debt_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of a company\'s assets financed by debt. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Long Term Debt/Capitalization',
        value: company.quick_numbers.solvency.long_term_debt_to_capitalization_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of a company\'s long-term debt relative to its total capitalization. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Current Ratio',
        value: company.quick_numbers.solvency.current_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay short-term obligations with its short-term assets. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
      {
        key: 'Cash Ratio',
        value: company.quick_numbers.solvency.cash_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay short-term obligations with its cash and cash equivalents. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
    ],
    efficiency: [
      {
        key: 'Return on Equity',
        value: company.quick_numbers.efficiency.return_on_equity_ratio * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company generates with the money shareholders have invested. Higher values suggest better performance.',
      },
      {
        key: 'Return on Capital Employed',
        value: company.quick_numbers.efficiency.return_on_capital_employed * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company generates with the money invested in its operations. Higher values suggest better performance.',
      },
      {
        key: 'Operating Profit Margin',
        value: company.quick_numbers.efficiency.operating_profit_margin * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company makes from its operations. Higher values suggest better performance.',
      },
      {
        key: 'Net Profit Margin',
        value: company.quick_numbers.efficiency.net_profit_margin * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company makes from its total revenue. Higher values suggest better performance.',
      },
      {
        key: 'Asset Turnover Ratio',
        value: company.quick_numbers.efficiency.asset_turnover_ratio * 100,
        formatter: fShortenNumber,
        helpText: 'Shows how efficiently a company uses its assets to generate sales. Higher values suggest better performance.',
      },
      {
        key: 'Inventory Turnover Ratio',
        value: company.quick_numbers.efficiency.inventory_turnover_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows how many times a company\'s inventory is sold and replaced over a period. Higher values suggest better performance.',
      },
      {
        key: 'Receivables Turnover Ratio',
        value: company.quick_numbers.efficiency.receivables_turnover_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows how many times a company\'s receivables are collected over a period. Higher values suggest better performance.',
      },
    ],
    growth: [
      {
        key: 'Revenue Growth (1Y)',
        value: company.quick_numbers.growth.revenue_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue has grown over the past year. Higher values suggest better performance.',
      },
      {
        key: 'Net Income Growth (1Y)',
        value: company.quick_numbers.growth.net_income_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s net income has grown over the past year. Higher values suggest better performance.',
      },
      {
        key: 'Revenue Growth (5Y)',
        value: company.quick_numbers.growth.five_y_revenue_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue has grown over the past five years. Higher values suggest better performance.',
      },
      {
        key: 'Net Income Growth (5Y)',
        value: company.quick_numbers.growth.five_y_net_income_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s net income has grown over the past five years. Higher values suggest better performance.',
      },
      {
        key: 'Operating Cashflow Growth (1Y)',
        value: company.quick_numbers.growth.operating_cashflow_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s operating cash flow has grown over the past year. Higher values suggest better performance.',
      },
      // changes below are already converted into percent
      {
        key: 'Expected revenue growth (Q)',
        value: company.quick_numbers.growth.expected_revenue_growth_quarter,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue is expected to grow over the next quarter. Higher values suggest better performance.',
      },
      {
        key: 'Expected revenue growth (Y)',
        value: company.quick_numbers.growth.expected_revenue_growth_year,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue is expected to grow over the next year. Higher values suggest better performance.',
      },
    ],
  })
