import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend, Line, LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import React from "react";

export const renderLineChart = (chart: any) => {
  if (!chart) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box sx={{m: 5}}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chart.chart_data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis
              dataKey="label"
              interval={Math.ceil(chart.chart_data.length / 6)} // Show approximately 6 labels
              tickFormatter={(label) => label.length > 20 ? `${label.substring(0, 18)}...` : label}
              padding={{left: 20, right: 20}}
            />
            <YAxis/>
            <Tooltip/>
            <Legend/>
            {Object.keys(chart.chart_data[0])
              .filter((key) => key !== "label")
              .map((key, index) => (
                <Line key={key} type="linear" dataKey={key} stroke={COLORS[index % COLORS.length]}/>
              ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export const renderBarChart = (chart: any) => {
  if (!chart) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={chart.chart_data}
                    margin={{bottom: 80, right: 50}}  // Increased bottom margin
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis
              dataKey="label"
              interval={0}
              tickFormatter={(label) => label.length > 20 ? `${label.substring(0, 18)}...` : label}
              angle={chart.chart_data.length > 5 ? 45 : 0}
              textAnchor={chart.chart_data.length > 5 ? "start" : "middle"}
            />
            <YAxis/>
            <Tooltip/>
            <Legend verticalAlign="bottom" align="center"/>
            <Box sx={{height: 10}}/>
            {Object.keys(chart.chart_data[0])
              .filter((key) => key !== "label")
              .map((key, index) => (
                <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]}/>
              ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

// Example colors for pie slices
const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#a4de6c", "#d0ed57", "#8dd1e1"];

export const renderPieChart = (chart: any) => {
  if (!chart || !chart.chart_data) {
    return null;
  }

  // Step 1: Calculate the total sum for percentage calculations
  const total = chart.chart_data.reduce((sum: number, entry: any) => sum + (entry.data || 0), 0);

  // Step 2: Enrich the data with calculated percentage
  const enrichedData = chart.chart_data.map((entry: any) => ({
    ...entry,
    percentage: total > 0 ? (entry.data / total) * 100 : 0, // Avoid division by zero
  }));

  // Custom label function to display inside the pie chart
  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, value, name}: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5; // Position inside the slice
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={12}>
        {`${percent * 100 > 1 ? (percent * 100).toFixed(1) : "<1"}%`}
      </text>
    );
  };

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box sx={{m: 5}}>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              data={enrichedData}
              dataKey="data"
              nameKey="label"
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
              label={renderCustomizedLabel} // Custom function for rendering labels inside the pie
              labelLine={false} // Hides lines connecting labels outside the pie
            >
              {enrichedData.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
              ))}
            </Pie>
            <Tooltip
              formatter={(value: any, name: any, entry: any) =>
                [`$${value.toLocaleString()} (${entry.payload.percentage.toFixed(1)}%)`, name]
              }
            />
            <Legend/>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
