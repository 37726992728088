import React, {useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography,
  Stack, Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import axios, {endpoints} from "../../utils/axios";

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;

  chatId: string;
  // optional, default null
  answerIndex?: number | null;
  selectedText?: string | null;
}

const ShareChatDialog: React.FC<ShareDialogProps> = ({open, onClose, chatId, answerIndex, selectedText}) => {
  const [copied, setCopied] = useState<boolean>(false); // Track if the link is copied
  const [shareLink, setShareLink] = useState<string>(""); // For the dialog
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    const generateLink = async () => {
      const body = {
        chat_id: chatId,
        selected_content: {
          answer_index: answerIndex,
          selected_text: selectedText,
        },
      };
      console.log("Generating link with body:", body);

      setIsLoading(true);
      try {
        const result = await axios.post(`${endpoints.chatNew}/share/`, body);
        console.log("Share successful:", result.data);
        setShareLink(result?.data?.url); // Assuming API returns shareCode
      } catch (error) {
        console.error("Error sharing:", error);
        alert("Failed to share the text.");
      } finally {
        setIsLoading(false);
      }
    };

    // call generate link on mount if open is true
    if (open) {
      console.log("Generating link...");
      generateLink();
    }
  }, [open, chatId, answerIndex, selectedText]);


  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box sx={{p: 2}}>
          <Skeleton variant="text" height={30}/>
          <Skeleton variant="text" height={30}/>
        </Box>
      );
    }

    return (
      <Stack direction="row" spacing={2} alignItems="center" sx={{mb: 2}}>
        <LinkIcon/>
        <Typography variant="body2"
                    color="text.secondary"
                    sx={{
          width: "calc(100% - 200px)",
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {shareLink}
        </Typography>
        <Stack direction="row"
               alignItems="center"
               justifyContent="center">
          <Box
            sx={{width: '100%'}}
          >
          {copied ? (
            <Button
              variant="contained"
              startIcon={<CheckIcon/>}
              disabled={copied} // Disable button when text is "Copied"
              sx={{width: '100%'}}
            >
              Copied!
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon/>}
              onClick={handleCopy}
              sx={{width: '100%'}}
            >
              Copy Link
            </Button>
          )}
          </Box>
        </Stack>
      </Stack>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Share link to chat
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default ShareChatDialog;
