import React, {useState, MouseEvent, KeyboardEvent, ReactNode} from 'react';
import {Menu, MenuItem} from '@mui/material';
import Box from "@mui/material/Box";
import Iconify from "../iconify";
import axios, {endpoints} from "../../utils/axios";
import ShareChatDialog from "./share-chat-dialog";
import {useAuthContext} from "../../auth/hooks"; // Import the dialog component

interface TextWithActionMenuProps {
  children: ReactNode;
  chatId: string;
  answerIndex: number;
  setSelectedText: (text: string) => void;
  setShareDialogOpen: (open: boolean) => void;
}

const TextWithActionMenu: React.FC<TextWithActionMenuProps> = ({children, chatId, answerIndex, setSelectedText, setShareDialogOpen}) => {
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);
  const { authenticated } = useAuthContext();

  const handleTextSelection = () => {
    if (!authenticated) {
      return;
    }

    const selection = window.getSelection();
    const text = selection?.toString().trim();

    if (text) {
      const range = selection!.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setAnchorPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
      setSelectedText(text);
    } else {
      setAnchorPosition(null);
    }
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    setTimeout(handleTextSelection, 0);
  };


  const handleMenuClose = () => {
    setTimeout(() => setAnchorPosition(null), 0);
  };


  return (
    <Box
      onMouseUp={handleMouseUp}
      tabIndex={0}
      aria-label="Selectable text area"
    >
      {children}
      {anchorPosition && (
        <Menu
          open={Boolean(anchorPosition)}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
        >
          <MenuItem onClick={() => {setShareDialogOpen(true); handleMenuClose();}}>Share Text<Iconify icon="mdi-light:share" sx={{ml: 1}}/></MenuItem>
          {/* Add more menu items as needed */}
        </Menu>
      )}
    </Box>
  );
};

export default TextWithActionMenu;
