// @mui
import React from 'react';
import {Avatar, Box, Paper, Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import ReactMarkdown from "react-markdown";
import isEqual from "lodash/isEqual";

import SourcesPopover from "../sources-popover/sources-popover";
import {renderBarChart, renderLineChart, renderPieChart} from "../../custom_analysis/analysis_charting";
import TextWithActionMenu from "../../utility_components/text-with-action-menu";
import ShareChatDialog from "../../utility_components/share-chat-dialog";

interface Paragraph {
  text: string;
  relevant_documents?: any[];
}

interface Interaction {
  ai_answer: { content: string };
  ai_answer_with_sources?: { content?: { paragraphs: Paragraph[] } };
  ai_chart: { content?: any };
}

interface AIAnswerProps {
  interaction: Interaction;
  chatId: string;
  answerIndex: number;
  onSourceClick: (src: any) => void;
  hoveredAnswerParagraphIndices: number[];
  setHoveredAnswerParagraphIndices: (indices: number[]) => void;
}


export default function ChatAIAnswer({
                                       interaction,
                                       chatId,
                                       answerIndex,
                                       onSourceClick,
                                       hoveredAnswerParagraphIndices,
                                       setHoveredAnswerParagraphIndices
                                     }: AIAnswerProps) {
  const theme = useTheme();
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [selectedText, setSelectedText] = React.useState<string>("");

  const chartLine = {
    "chart_type": "line",
    "chart_title": "NVIDIA Revenue and Free Cash Flow Over Time",
    "chart_data_scale": "Millions of USD",
    "chart_data": [
      {
        "label": "2024",
        "Revenue": 60922,
        "Free Cash Flow": 27021
      },
      {
        "label": "2023",
        "Revenue": 26974,
        "Free Cash Flow": 3808
      },
      {
        "label": "2022",
        "Revenue": 26914,
        "Free Cash Flow": 8132
      },
      {
        "label": "2021",
        "Revenue": 16675,
        "Free Cash Flow": 4694
      },
      {
        "label": "2020",
        "Revenue": 10918,
        "Free Cash Flow": 4272
      },
      {
        "label": "2019",
        "Revenue": 11716,
        "Free Cash Flow": 3143
      },
      {
        "label": "2018",
        "Revenue": 9714,
        "Free Cash Flow": 2909
      },
      {
        "label": "2017",
        "Revenue": 6910,
        "Free Cash Flow": 1496
      },
      {
        "label": "2016",
        "Revenue": 5010,
        "Free Cash Flow": 1089
      },
      {
        "label": "2015",
        "Revenue": 4681,
        "Free Cash Flow": 783
      }
    ]
  }
  const chartBar = {
    "chart_type": "bar",
    "chart_title": "NVIDIA Revenue Breakdown by Geographic Area",
    "chart_data_scale": "Millions of USD",
    "chart_data": [
      {
        "label": "United States",
        "Revenue": 14800
      },
      {
        "label": "Singapore",
        "Revenue": 7697
      },
      {
        "label": "China (including Hong Kong)",
        "Revenue": 5416
      },
      {
        "label": "Taiwan",
        "Revenue": 5153
      },
      {
        "label": "Other countries",
        "Revenue": 2016
      }
    ]
  }
  const chartPie = {
    "chart_type": "pie",
    "chart_title": "NVIDIA Revenue Breakdown by Product Segment",
    "chart_data_scale": "Millions of USD",
    "chart_data": [
      {"label": "Compute & Networking", "data": 47405},
      {"label": "Graphics", "data": 13517},
      {"label": "All Other", "data": 0}
    ]
  }

  const renderChart = (chart: any) => {
    if (!chart) {
      return null;
    }

    if (chart.chart_type === "line") {
      return renderLineChart(chart);
    }

    if (chart.chart_type === "bar") {
      return renderBarChart(chart);
    }
    if (chart.chart_type === "pie") {
      return renderPieChart(chart);
    }
    return null;
  };

  const renderAIAnswerWithoutSources = (interaction_: Interaction) => (
    <Stack key={`ai-question-${answerIndex}`} id={`ai-answer-${answerIndex}`} direction="row" spacing={2}>
      <Avatar sx={{height: {xs: 25, lg: 35}, width: {xs: 25, lg: 35}}} alt="logo"
              src="/assets/images/logo/logo_without_text.png"/>
      <Paper
        sx={{
          py: {xs: 0.5, lg: 1.5},
          px: 1.5,
          flexGrow: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack
          sx={{mb: 0.5}}
          alignItems={{sm: 'center'}}
          justifyContent="space-between"
          direction={{xs: 'column', sm: 'row'}}
        >
          <Box sx={{typography: 'subtitle2'}}>ValueHunter</Box>
        </Stack>

        <Box
          sx={{
            typography: 'body2',
            color: theme.palette.grey[700],
          }}
        >
          <TextWithActionMenu chatId={chatId} answerIndex={answerIndex} setSelectedText={setSelectedText}
                              setShareDialogOpen={setShareDialogOpen}>
            <ReactMarkdown>{interaction_.ai_answer.content}</ReactMarkdown>
          </TextWithActionMenu>
        </Box>
        {/* Chart */}
        {interaction_?.ai_chart?.content && renderChart(interaction_.ai_chart.content)}
        <ShareChatDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          chatId={chatId}
          answerIndex={answerIndex}
          selectedText={selectedText}
        />
      </Paper>
    </Stack>
  );

  const renderAIAnswerWithSources = (interaction_: Interaction) => (
    <Stack key={`ai-question-${answerIndex}`} id={`ai-answer-${answerIndex}`} direction="row" spacing={2}>
      <Avatar sx={{height: {xs: 25, lg: 35}, width: {xs: 25, lg: 35}}} alt="logo"
              src="/assets/images/logo/logo_without_text.png"/>
      <Paper
        sx={{
          py: {xs: 0.5, lg: 1.5},
          px: 1.5,
          flexGrow: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack
          sx={{mb: 0.5}}
          alignItems={{sm: 'center'}}
          justifyContent="space-between"
          direction={{xs: 'column', sm: 'row'}}
        >
          <Box sx={{typography: 'subtitle2'}}>ValueHunter</Box>
        </Stack>

        <Box
          sx={{
            typography: 'body2',
            color: theme.palette.grey[700],
          }}
        >
          {interaction_.ai_answer_with_sources?.content?.paragraphs.map((paragraph, paragraphIndex) => (
            <Box key={`content-paragraph-${paragraphIndex}`} sx={{position: 'relative'}}>
              <Box
                sx={{
                  borderRadius: 1,
                  padding: 0.1,
                  backgroundColor: isEqual(hoveredAnswerParagraphIndices, [answerIndex, paragraphIndex]) ? theme.palette.grey[300] : "transparent",
                  transform: isEqual(hoveredAnswerParagraphIndices, [answerIndex, paragraphIndex]) ? "scale(1.02)" : "scale(1)",
                  // boxShadow: isEqual(hoveredAnswerParagraphIndices, [key, index]) ? theme.customShadows.dropdown : "none",
                  transition: "transform 0.1s ease-in-out",
                }}
              >

                <TextWithActionMenu chatId={chatId} answerIndex={answerIndex} setSelectedText={setSelectedText}
                                    setShareDialogOpen={setShareDialogOpen}>
                  <ReactMarkdown>{paragraph.text}</ReactMarkdown>
                </TextWithActionMenu>

              </Box>
              <Stack direction="row" justifyContent="flex-end">
                <SourcesPopover
                  paragraph={paragraph}
                  onButtonHover={() => setHoveredAnswerParagraphIndices([answerIndex, paragraphIndex])}
                  onButtonHoverLeave={() => setHoveredAnswerParagraphIndices([])}
                  onSourceClick={onSourceClick}
                />
              </Stack>
            </Box>
          ))}

          {/* Chart */}
          {interaction_?.ai_chart?.content && renderChart(interaction_.ai_chart.content)}
        </Box>
        <ShareChatDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          chatId={chatId}
          answerIndex={answerIndex}
          selectedText={selectedText}
        />
      </Paper>
    </Stack>
  );

  if (interaction.ai_answer_with_sources?.content?.paragraphs && interaction.ai_answer_with_sources.content.paragraphs.length > 0) {
    return renderAIAnswerWithSources(interaction);
  }
  if (interaction.ai_answer?.content !== "") {
    return renderAIAnswerWithoutSources(interaction);
  }
  return null;
};

