import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Stack from "@mui/material/Stack";

interface PredefinedPromptItemProps {
  sendQuestion: (question: string) => void;
  promptText: string;
  setDisabledButtons: (disabled: boolean) => void;
  disabledButton: boolean;
  height?: string
}

export default function PredefinedPromptItem({
                                               sendQuestion,
                                               promptText,
                                               setDisabledButtons,
                                               disabledButton,
                                               height = "100%"
                                             }: PredefinedPromptItemProps) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = async () => {
    setDisabledButtons(true);
    try {
      await sendQuestion(promptText);
    } finally {
      setDisabledButtons(false);
    }
  };

  const boxHeight = height;

  return (
    <Box
      sx={{
        px: 2,
        width: "100%",
        height: boxHeight,
        transition: "transform 0.2s", // Add a transition for the transform property
        "&:hover": {
          transform: "scale(1.05)", // Scale the button slightly when hovered
        },
      }}
    >
      <Button
        disabled={disabledButton}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          p: 2,
          pl: 3,
          pr: 3,
          bgcolor: "info.lighter",
          fontWeight: 100,
          width: "100%",
          height: "100%",
          transition: "background-color 0.2s, box-shadow 0.2s, color 0.2s", // Add transitions for color, box-shadow, and background-color
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow when hovered
          },
        }}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{width: "100%"}}>
          <Box flexGrow={1} display="flex" justifyContent="center">
            {promptText}
          </Box>
          <Box flexShrink={0}>
            {isHovered ? <SendOutlinedIcon /> : <TipsAndUpdatesOutlinedIcon />}
          </Box>
        </Stack>
      </Button>
    </Box>
  );
}
