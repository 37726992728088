// Update the query parameter when a chat is selected or the new chat button is clicked
import React, {useState} from 'react';
import Box from '@mui/material/Box';

// GA
import ReactGA from "react-ga4";

// components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {ArrowBack, Create, IosShare} from '@mui/icons-material';
import {Button, Collapse, Snackbar, Stack, Tooltip, Typography} from "@mui/material";
import Alert from "@mui/material/Alert";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useNavigate, useSearchParams} from "react-router-dom";
import Iconify from "../../iconify";

import ChatCreateDialog from "../chat-create-dialog/chat-create-dialog";
import {Chat, ChatMany, newChatTemplate} from "../../../layouts/chat/types";
import {Company} from "../../../layouts/company/types";
import {fDateTime, toUserTimezone} from "../../../utils/format-time";
import {ScoredCompany2} from "../../company/types";
import CompanyLogo from "../../company/constants";
import RemainingCredits from "../../remaining-credits/remaining-credits";
import ShareChatDialog from "../../utility_components/share-chat-dialog";
import {useAuthContext} from "../../../auth/hooks";
import LoginRegisterContent from "../../../layouts/credits/login-register-content";


interface ChatLeftSidebarProps {
  chats: ChatMany[];
  company: ScoredCompany2;
  companies: Map<number, Company>;
  closeDialog?: () => void;
  chatCreated: () => void;
  setSelectedChat: (chat: Chat) => void;
  selectedChat: Chat;
}

export default function ChatLeftSidebar({
                                          chats,
                                          company,
                                          companies,
                                          closeDialog,
                                          chatCreated,
                                          selectedChat,
                                          setSelectedChat,
                                        }: ChatLeftSidebarProps) {
  const theme = useTheme();
  const {authenticated} = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSuccess = () => {
    setSuccessOpen(true);
    chatCreated();
  };

  const handleError = () => {
    setErrorOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (id: string) => {
    // getChat(id);
    ReactGA.event({category: 'Button', action: 'Click', label: 'Select Chat'});
    setSearchParams({chat: id});
  };

  const handleNewChatClick = () => {
    setSelectedChat({...newChatTemplate});
    setSearchParams({chat: '1'});
  };

  if (!authenticated) {
    return (
      <Box sx={{bgcolor: 'background.neutral', height: '100%'}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{height: '100%'}}>
          <LoginRegisterContent/>
        </Stack>
      </Box>
    )

  }


  return (
    <Box sx={{bgcolor: 'background.neutral', height: '100%'}}>
      <Stack direction="column" justifyContent="space-between" sx={{height: '100%'}}>
        {/* Top pinned section */}
        <Box>
          <ListItem sx={{width: '100%'}}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between"
                   sx={{width: '100%'}}>
              <Button onClick={closeDialog}><ArrowBack sx={{mr: 1}}/>Back</Button>
              <Stack direction="row">
                <Button
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  disabled={!selectedChat.id}
                >
                  <Tooltip title="Share link" arrow>
                    <IosShare/>
                  </Tooltip>
                </Button>
                <Button onClick={handleNewChatClick}>
                  <Tooltip title="Create new chat" arrow>
                    <Create/>
                  </Tooltip>
                </Button>
              </Stack>

              <ShareChatDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                chatId={selectedChat.id}
              />
            </Stack>
          </ListItem>
        </Box>

        {/* Scrollable chats list */}
        <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
          <List sx={{
            flex: 1,
            width: '100%',
            maxWidth: 360,
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            {/* Show New Chat */}
            <Collapse in={selectedChat && !selectedChat.id} sx={{width: '100%'}}>
              <ListItem button key="new-chat-key" onClick={handleNewChatClick} sx={{
                pl: 3,
                backgroundColor: theme.palette.action.hover,
              }}>
                <Typography variant="body2" sx={{
                  backgroundColor: theme.palette.action.hover,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                }}>
                  New Chat
                </Typography>
              </ListItem>
            </Collapse>

            {/* Show Chats */}
            <Box sx={{overflowY: 'auto', maxHeight: "100%"}}>
              {chats.map((chat, idx) => (
                <ListItem button key={chat.id} onClick={() => handleListItemClick(chat.id)} sx={{
                  pl: 3,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  backgroundColor: selectedChat.id === chat.id ? theme.palette.action.selected : 'transparent',
                }}>
                  <Stack direction="row" spacing={2}>
                    <CompanyLogo ticker={company.ticker} logo_url={company.image_url} width={22} height={22}
                                 borderRadius={1}/>
                    <Typography variant="body2" sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: selectedChat.id === chat.id ? 'bold' : 'normal',
                    }}>
                      {`${fDateTime(toUserTimezone(chat.updated_at))}`}
                    </Typography>
                  </Stack>
                </ListItem>
              ))}
            </Box>
          </List>
        </Box>
        <Box sx={{p: 2}}>
          <RemainingCredits/>
        </Box>
      </Stack>

      {/* Create chat */}
      <ChatCreateDialog companies={companies} dialogOpen={open} handleSuccess={handleSuccess}
                        handleError={handleError} handleClose={handleClose}/>
      {/* Success */}
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={() => setSuccessOpen(false)}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{width: '100%'}}>
          Chat created successfully!
        </Alert>
      </Snackbar>

      {/* Error */}
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error" sx={{width: '100%'}}>
          There is an issue with chat creation!
        </Alert>
      </Snackbar>
    </Box>
  );
}
